const quarantineRouter = {
  route: null,
  name: Symbol('center'),
  title: '动物检疫证管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/quarantine/', // 文件路径
  order: null,
  inNav: true,
  permission: ['动物检疫证管理'],
  children: [
    // 芯片号管理
    {
      title: '检疫证列表',
      type: 'view',
      name: Symbol('center'),
      route: '/quarantine/certlist/index',
      filePath: 'view/quarantine/certlist/index.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['检疫证列表'],
    },
    // 堪萨斯芯片爬虫
    {
      title: '检疫证爬虫',
      type: 'view',
      name: Symbol('center'),
      route: '/quarantine/spider/index',
      filePath: 'view/quarantine/spider/index.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['检疫爬虫列表'],
    },
  ],
}

export default quarantineRouter
